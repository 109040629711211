import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'

// images

const AboutPage = () => (
  <Layout>
    <Seo
      title="About us"
      description="About Ecotech Restoration Services"
      canonical="https://ecotechrestoration.ca/about-us/"
    />

    {/* Heros */}
    <div className="heroMobile">
      <StaticImage
        src="../images/heros/about.jpg"
        // maxHeight={600}
        quality={95}
        alt="About Ecotech Restorations"
      />
    </div>

    <SiteWidthWrapper>
      <h1
        style={{
          marginTop: `0`,
          paddingTop: `0`,
        }}>
        Ecotech Restoration Inc. is a Full Service Emergency Restoration Company
      </h1>
      <p>
        We are Vancouver’s premier providers of restoration services in the B.C.
        lower mainland area.
      </p>
      <p>
        Our goal is to provide you with the very best Vancouver Restoration
        Services with an emphasis on customer service.
      </p>
      <p>
        We understand how overwhelming sudden unexpected events can be and we
        are ready to assist you with your flood and water damage, mold
        remediation, fire restoration, wind storm damage, or other catastrophe
        cleanups.
      </p>
      <p>
        Our team is here to make your restoration as simple and easy as
        possible.
      </p>
    </SiteWidthWrapper>
  </Layout>
)

export default AboutPage
